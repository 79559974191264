<template>
  <div class="desktopLayoutClass">
    <v-app>
      <div class="headingtitle">
        <!-- <commonHomeHeader :nurseBookingPage=true></commonHomeHeader> -->
        <img class="brand-logo-class clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/MyMedicine_Logo.svg" @click="openDHPHome()">
      </div>
      <div v-if="skeletonLoaderFlag">
        <div class="" >
          <v-skeleton-loader elevation="0" type="table-heading" />
        </div>
        <div class="pa-2" >
          <v-skeleton-loader elevation="0" type="image" />
        </div>
        <div class="" >
          <v-skeleton-loader elevation="0" type="list-item-avatar" />
        </div>
        <div class="d-flex flex-row pa-4" >
          <v-skeleton-loader class="ma-4" style="height: 140px" elevation="0" type="card-avatar" />
          <v-skeleton-loader class="ma-4" style="height: 140px" elevation="0" type="card-avatar" />
          <v-skeleton-loader class="ma-4" style="height: 140px" elevation="0" type="card-avatar" />
        </div>
        <div class="pa-2" >
          <v-skeleton-loader elevation="0" type="card" />
        </div>
        <div class="pa-4" style="text-align:center" >
          <v-skeleton-loader elevation="0" type="button" />
        </div>
      </div>
      <div v-else class="body">
        <div class="pt-5 banners-segment" v-if="banners.length > 0">
          <bannerComponent :key="bannersComponentKey" :banners="banners" banner_type="BANNERS-ONE-PER-SLIDE">
          </bannerComponent>
        </div>
        <div class="body_div mb-15">
          <div class="unavailabe_div" v-if="!nurseUnavailable">
            <img style="margin-top: 5px;"
              src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/nurseUnavailableRedEmoji.svg " />
            <div class=" sub_title">
              Nurses Unavailable!
              <div class="content_div">
                Our nurses are busy. We regret for the inconvenience caused.
              </div>
            </div>
          </div>

          <div class="booking_content booking_heading">
            <img class="heart_icon_class" src="https://s3iconimages.mymedicine.com.mm/heartIcon.svg" />
            Book an appointment
            <!-- <img class="info_icon_class" src="https://s3iconimages.mymedicine.com.mm/info.svg" /> -->
          </div>
          
          <nurseTypes v-if="showChild" :nurseTypesProp="nurseTypes"></nurseTypes>
          
            <v-btn :class="!nurseUnavailable ? 'bookAppointment_button_disable' : 'bookAppointment_button_enable'"
            :disabled="!nurseUnavailable" @click="redirectToAppointment()">
            <img class="phone_icon_class" src="https://s3iconimages.mymedicine.com.mm/phone_cc.svg" />
            <span class="bookAppointment_button_content">Book Appointment</span>
          </v-btn>
        </div>
        <div v-if="activeAppointmentDetails && typeof activeAppointmentDetails == Object && Object.keys(activeAppointmentDetails).length > 0 && activeAppointmentDetails.nurse_status !== 3" style="position: sticky; bottom: 0; z-index: 1000;">
          <appointmentStatus :activeAppointmentDetails = activeAppointmentDetails></appointmentStatus>
        </div>
      </div>
      <!-- Bottom Navigation Bar (Mobile) -->
      <div class="bottom-navbar-segment mobile-bottom-nav-bar">
        <bottom-nav-bar-component :selectedOption="4" />
      </div>
    </v-app>
  </div>
</template>

<script>
import bannerComponent from "../../../components/nurseBookingPageComponents/bannerComponents.vue";
import nurseTypes from "../../../components/nurseBookingComponents/nurseTypes.vue";
import appointmentStatus from "../../../components/nurseBookingPageComponents/appointmentStatus.vue";
import commonHomeHeader from "../../../components/navigationComponents/commonHomeHeader.vue";
import { axios_auth_instance_nurseBooking } from '../../../utils/axios_utils';
import { mixinFunctions } from '../../../mixins/index';
export default {
  components: {
    bannerComponent,
    nurseTypes,
    appointmentStatus,
    commonHomeHeader,
    'bottom-nav-bar-component': () => import("../../../components/nurseBookingPageComponents/bottomNavBarComponent.vue")
  },
  mixins:[mixinFunctions],
  data() {
    return {
      banners: [],
      bannersComponentKey: 1,
      nurseUnavailable: false,
      nurseTypes: [],
      showChild: false,
      token: null,
      pageLoading: true,
      activeAppointmentDetails: [],
      skeletonLoaderFlag: true,
    };
  },
  methods: {
    openDHPHome() {
      window.open('https://mymedicine.com.mm/', '_blank');
    },
    redirectToAppointment(path) {
      this.$router.push('/nurseBooking');
    },
    redirectToCommonHome() {
			if (!window.xm) {
        this.$router.push({
          name: 'CustomerHomeScreen'
        });
      } else {
        if (this.$router.history.current.name == 'CommonHomePage') {
          this.$router.go();
        } else {
          this.$router.push({
            path: '/'
          });
        }
      }
		},
    async fetchNurseBookingHomeDetails() {
      try {
        let commonHomeResponse = await axios_auth_instance_nurseBooking.get("/pagewise/nurseBookingHome");
        this.banners = commonHomeResponse.data.data.mobileBannerImages;
        this.nurseUnavailable = commonHomeResponse.data.data.isNurseAvailable;
        this.nurseTypes = commonHomeResponse.data.data.nurseTypes;
        this.activeAppointmentDetails = commonHomeResponse.data.data.activeAppointments;
        this.showChild = true;
        this.pageLoading = false;
        this.skeletonLoaderFlag = false;
      } catch (error) {
        if (error.response.status === 401) {
          this.$cookies.remove('customerToken');
          this.$router.push({
            name: "Login",
          });
        }
      }

    },
  },

  mounted() {
    this.$store.dispatch('cleanNurseBookingValues');
    if (window.xm) {
      this.token = localStorage.getItem('customerToken');
    } else {
      this.token = this.$cookies.get('customerToken');
    }
    this.bannersComponentKey++;
    this.fetchNurseBookingHomeDetails();
    this.userPageViewTable('NBAHomePage');
  },
};
</script>

<style lang="scss" scoped >
  @import '../../../scss/global.scss';
  @import '../../../scss/classes.scss';
.loader-wrapper {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}
.body {

  max-height: 90vh;
  overflow-y: scroll;

}

.headingtitle {
  border-bottom: 1px solid #e0e0e0 !important;
  background:  #fff !important;
  height: 56px;
  padding: 0px 1.5rem;
  display: flex;
  align-items: flex-start;
}

.banner_div {
  /* padding: 24px 20px; */
  flex-direction: column;
  align-items: flex-start;
  /* gap: 44px; */
  background: #fff;
}

.body_div {
  padding: 0.5rem;
  padding-top: 20px;
  /* Adjust padding as needed */
  margin: 0.5rem;
  /* Adjust margin as needed */
  border-top: 1px solid #e0e0e0 !important;
  // border-bottom: 2px solid #e0e0e0;
  display: flex;
  /* padding: 24px 24px 32px 24px; */
  flex-direction: column;
  gap: 32px;
}

.unavailabe_div {

  border-radius: 0.5rem;
  background: #f4f6fa !important;
  display: flex;
  width: 100%;
  /* Make it full width */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  /* Adjust gap as needed */
}

.unavailabe_div_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.sub_title {
  color: #333 !important;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.content_div {
  color: #828282 !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  margin-bottom: 5px;
}

.booking_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}

.booking_heading {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.booking_content {
  color: #333 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  text-transform: capitalize;
}

.nurseType_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.specification_div {
  border-radius: 8px;
  background:  #f4f6fa !important;
  display: flex;
  padding: 16px 16px 18px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  align-self: stretch;
}

.specification_title_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  align-self: stretch;
  color:  #333 !important;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.specification_content_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  color:  #828282 !important;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 184.615% */
}

.bookAppointment_button_disable {
  border-radius: 4px;
  background: #bdbdbd !important;
  display: flex;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}

.bookAppointment_button_enable {
  display: flex;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: #48acef !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}

.bookAppointment_button_content {
  color:  #fff !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
}

.availabe_status_card {
  display: flex;
  padding: 75px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid #e0e0e0 !important;
  background:  #eaf4ff !important;
  backdrop-filter: blur(25px);
  margin-top: 100px;
}

.info_icon_class {
  margin-left: auto;
  /* Push the element to the right end */
}

.phone_icon_class {
  width: 18px;
  height: 20px;
  margin-right: 10px;
}

.banners-segment {
  width: 100%;
  height: auto;

  @media (max-width: 600px) {
    padding-top: calc($content-padding-mobile / 2);
    padding-bottom: $content-padding-mobile;
    padding-left: $margin-header-x-mobile;
    padding-right: $margin-header-x-mobile;
  }

  @media (min-width: 601px) and (max-width: 1264px) {
    padding-top: calc($content-padding-medium / 2);
    padding-bottom: calc($content-padding-medium / 2);
    padding-left: $margin-header-x-medium;
    padding-right: $margin-header-x-medium;
  }

  @media (min-width: 1265px) {
    padding-top: calc($content-padding-web / 2);
    padding-bottom: calc($content-padding-web / 2);
    padding-left: $margin-header-x-web;
    padding-right: $margin-header-x-web;
  }
}

.loader-mobile, .mobile-bottom-nav-bar, .display-mobile {
	@media (max-width: 600px) {
        
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: none !important;
	}

	@media (min-width: 1265px) {
		display: none !important;
	}
}

.brand-logo-class {
	width: auto;
	object-fit: contain;
  height: 40px;
  margin-top: 6px;
}
</style>